.accordion{
    --bs-accordion-bg: #EDE2D1!important;
    --bs-accordion-border-color: black!important;
  --bs-accordion-active-bg: #EDE2D1!important;
  --bs-accordion-active-color: black!important;
  --bs-accordion-border-width: 0 !important;
  --bs-accordion-btn-focus-box-shadow: none!important;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}

.song{
  margin-left:-83px;
  overflow-x: hidden;
}

.number{
  background: #23211e;
  border-radius: 20px;
  font-family: Termina;
  color:  #ede2d1;
width:320px;
height:70px;
padding: 20px 0 0 0;
  font-size:18px;
}

.price{
  font-family: Termina;
  color: #23211e;
}

.numberinput{
  background: #23211e;
  border: none;
  height:30px;
  border-radius: 10px;
  color:  #ede2d1;
  padding:10px;
  text-align: center;
  font-family: Termina;
  margin-left: 10px;
  max-width:100px;
  max-width: 50px;
  border: 1px solid white;
}

.supply{
  background: #23211e;
  max-width:220px;
  margin: 0 auto;
  color:  #ede2d1;
  font-family: Termina;
  text-align: left;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  font-size: 18px;
  padding-left: 20px;

}

.input{
  border: none;
  border-radius: 10px;
  border: 1px solid white;

}

.submit{
  border:none;
  border-radius:20px;
  height:35px;
  width:200px;
  margin-top: 10px;
  background: #ede2d1;
}

.link{
  text-decoration: none;
  
}

@media only screen and (max-width: 600px) {
  .supply{
    padding: 5px 0 ;
    font-size: 18px;
    border-radius: 20px;
    text-align: center;
  }
  .number{
    padding: 10px 15px ;
    font-size: 18px;
    border-radius: 20px;
    text-align: center;

  }
  .numberinput{
    font-size: 18px;
    width: 40px;
    



  }


}

.social{
  height: 200%;
}

.trxlink{
  text-decoration: none;
  /* text-decoration: underline; */
}